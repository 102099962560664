import React, { Fragment } from "react"
// import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    {/* Hi my name is <Styled.a href="http://example.com/">Omar</Styled.a> */}
    Hi my name is Omar. 
  </Fragment>
)